import React, { useEffect, useState } from "react"
import { api } from "../config/api"

type VolunteerInfo = {
  name: string;
  surname: string;
  level: number;
  yearlyReviewsCount: number;
}

type ReviewInfo = Array<{
  hasReview: boolean;
  week: string;
  examinees: Array<{
    id: number;
    level: number;
  }>
}>

// From ChatGPT
function getWeekNumber(date) {
  // Copy date so don't modify original
  const d = new Date(date);
  // Day number of the week (0 = Sunday, ... , 6 = Saturday)
  const dayNum = d.getUTCDay() || 7;

  // Set the date to the previous Monday
  d.setUTCDate(d.getUTCDate() - dayNum + 1);

  // Get the first day of the year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

  // Calculate the difference between the two dates (in days)
  const dayDiff = (d.getTime() - yearStart.getTime()) / (60 * 60 * 24 * 1000);
  // Calculate the week number
  const weekNo = Math.ceil((dayDiff + 1) / 7);

  return `${d.getUTCFullYear().toString().slice(2)}\n#${weekNo.toString().padStart(2, '0')}`;
}

const sortDateDesc = (a, b) => new Date(b).getTime() - new Date(a).getTime()

export default () => {
  const [reviewsState, setReviews] = useState<Record<string, ReviewInfo>>({});
  const [volunteersState, setVolunteers] = useState<Record<string, VolunteerInfo>>({});
  const [weeksState, setWeeks] = useState<string[]>([]);

  useEffect(() => {
    const getData = async () => {
      const {reviews, volunteers, weeks} = await api.get<{
        reviews: Record<string, ReviewInfo>,
        volunteers: Record<string, VolunteerInfo>,
        weeks: string[]
      }>('volunteers_history');

      const reviewsCleaned = Object.entries(reviews).reduce((acc, [vId, vReviews]) => {
        if (vReviews.find(r => r.hasReview))
          acc[vId] = vReviews.sort((a, b) => sortDateDesc(a.week, b.week));
        return acc;
      }, {})

      setReviews(reviewsCleaned)
      setVolunteers(Object.entries(volunteers).reduce((acc, [vId, v]) => {
        if (vId in reviewsCleaned) {
          acc[vId] = {
            ...v,
            yearlyReviewsCount: reviewsCleaned[vId].filter(({hasReview}) => hasReview).length
          }
        }
        return acc
      }, {}))
      setWeeks(weeks.sort(sortDateDesc))
    }
    getData()
  }, [])

  return (
    <div className="table-wrapper">
    <style>
      {`
      th, td {
        padding: 2px;
        border: 1px solid #eee;
      }
      .table-wrapper {
        overflow: auto;
        max-width: 100%;
        max-height: 90vh;
      }
      table {
        border-collapse: separate;
        width: 100%;
      }
      /* Fix the first row */
      th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #f5f3f3;  /* To prevent seeing through the cells when scrolling */
      }

      /* Fix the first column */
      .fixed-cell {
        position: sticky;
        z-index: 1;
        left: 1px;
        background-color: #f5f3f3;
      }

      /* To move the next fixed cells to the right */
      .fixed-cell:nth-child(2) {
        left: 30px; /* Adjust based on the width of the first column */
      }

      .fixed-cell:nth-child(3) {
        left: 57px; /* Adjust based on the width of the first two columns combined */
      }
      /* Fix the top-left cell, which is both a header and a fixed cell */
      th.fixed-cell {
        z-index: 3;
      }
      `}
    </style>
    <table>
      <thead>
        <tr>
          <th className="fixed-cell">Lvl</th>
          <th className="fixed-cell">За год</th>
          <th className="fixed-cell">Волонтер\номер&nbsp;недели</th>
          {weeksState.map(week => <th key={'th'+week}>{getWeekNumber(week)}</th>)}
        </tr>
      </thead>
      <tbody>
        {Object.entries(volunteersState).map(([volunteerId, volunteerInfo]) => (
          <tr key={volunteerId}>
            <td className="fixed-cell">{volunteerInfo.level}</td>
            <td className="fixed-cell">{volunteerInfo.yearlyReviewsCount}</td>
            <td className="fixed-cell" style={{whiteSpace: 'nowrap'}}>{volunteerInfo.name} {volunteerInfo.surname}</td>
            {reviewsState[volunteerId].map(({week, hasReview, examinees}) =>
            <td
              style={{backgroundColor: hasReview ? examinees.length === 1 ? '#e5f7e9' : '#fafad2' : 'none'}}
              key={volunteerId+week}>{hasReview ? examinees.map(e => e.level).join(',') : ''}</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  )
}