import React from "react";

export default [
  {
    id: "graduateDate",
    Header: "Дата выпуска",
    defaultVisibility: true,
    accessor: "additional.graduateDate",
  },
  {
    id: "homeworksUrl",
    defaultVisibility: true,
    Header: "Все домашки",
    Cell: ({original: {additional}}) => (<a href={additional.homeWorkUrl}>Домашка</a>),
  },
  {
    id: "additional.mentorCourseOpened",
    defaultVisibility: true,
    Header: "Курсы открыты",
    accessor: 'additional.mentorCourseOpened',
    Cell: ({original: {additional}}) => additional.mentorCourseOpened ? "да" : "нет",
  },
  {
    id: "additional.inflatedMarksAmount",
    defaultVisibility: true,
    Header: "Был добряком",
    width: 80,
    accessor: 'additional.inflatedMarksAmount',
    Cell: ({original: {additional}}) => `${additional.inflatedMarksAmount || 0} раз`,
  },
  {
    id: "additional.eduAttempts",
    defaultVisibility: true,
    Header: "Стартовал",
    width: 80,
    accessor: 'additional.eduAttempts',
    Cell: ({original: {additional}}) => `${additional.eduAttempts || 0} раз`,
  },
  {
    id: "bestReviewerRating",
    defaultVisibility: true,
    Header: "Лучший ревьювер",
    width: 100,
    accessor: ({additional}) => 100 * additional.bestReviewerRating / (additional.reviewsAmountAsReviewer || 1),
    Cell: ({original: {additional}}) => (
      <div
        className="tooltip is-tooltip-bottom"
        data-tooltip="лучший ревьювер / всего ревью / соотношение"
      >
        {additional.bestReviewerRating || 0}&nbsp;/&nbsp;
        {additional.reviewsAmountAsReviewer || 0}&nbsp;/&nbsp;
        {~~(100 * additional.bestReviewerRating / (additional.reviewsAmountAsReviewer || 1))}%
      </div>
    ),
    className: "has-text-right",
  },
  {
    id: "avgMarks",
    Header: "Средние оценки",
    defaultVisibility: true,
    columns: [
      {
        id: "avgMarks-general",
        Header: "Основные",
        accessor: (d) => d.additional.avgMarks.general,
        className: "has-text-right",
      },
      {
        id: "avgMarks-reviewer",
        Header: "Ревьюер",
        accessor: (d) => d.additional.avgMarks.reviewer,
        className: "has-text-right",
      },
      {
        id: "examinee-examinee",
        Header: "Экзаменующийся",
        accessor: (d) => d.additional.avgMarks.examinee,
        className: "has-text-right",
      },
    ],
  },
];
