import React from "react";
import { Navbar } from "react-bulma-components";
import { NavLink } from "react-router-dom";
import NAV_BAR_ITEM_LIST from "../config/tableColumns";

type NavBarState = {
  navActive: boolean
}

export default class TheNavBar extends React.Component<{}, NavBarState> {
  constructor(props) {
    super(props);
    this.state = {
      navActive: false,
    };
  }

  render() {
    const navElements = [
      {
        path: "group-start-dates",
        title: "Даты стартов групп",
      },
      ...NAV_BAR_ITEM_LIST,
      {
        path: "review-planning",
        title: "Планирование ревью",
      },
      {
        path: "volunteers-history",
        title: "История воло ревью",
      },
    ].map((item) => (
      <Navbar.Item key={item.path} renderAs="span">
        <NavLink
          onClick={() => this.setState({ navActive: false })}
          to={`/${item.path}`}
          activeClassName="is-active has-text-weight-bold"
        >
          {item.title}
        </NavLink>
      </Navbar.Item>
    ));

    const { navActive } = this.state;

    return (
      <Navbar fixed="top" active={navActive}>
        <Navbar.Brand>
          <Navbar.Item renderAs="span">
            <Navbar.Burger onClick={() => this.setState({ navActive: !navActive })} />
            <NavLink to="/" className="has-text-weight-bold has-text-black">SCS Адміністрування</NavLink>
          </Navbar.Item>
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container>
            {navElements}
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>
    );
  }
}
