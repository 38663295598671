import React from "react";
import { ToastContainer } from "react-toastify";
import { Redirect, Route, Switch } from "react-router-dom";

import TheNavBar from "./components/TheNavBar";

import Students from "./pages/Students";
import ReviewPlanning from "./pages/ReviewPlanning";
// import GlobalMarks from "./pages/GlobalMarks";
import GroupStartDates from "./pages/GroupStartDates";
import AccessDenied from "./pages/AccessDenied";
import Logs from "./pages/Logs";
import NoMatch from "./pages/404";

import 'bulma/css/bulma.css'
import "./App.css";
import VolunteersHistory from "./pages/VolunteersHistory";

export default () =>
  (
      <div className="App">
        <header className="app-header">
          <TheNavBar />
        </header>
        <main>
          <Switch>
            <Route
                exact
                path="/"
                render={() => (
                    <Redirect to="/group-start-dates" />
                )}
            />
            <Route path="/group-start-dates" component={GroupStartDates} />
            <Route path="/logs" component={Logs} />
            <Route path="/students/:type" component={Students}/>
            <Route path="/review-planning" component={ReviewPlanning} />
            {/*<Route path="/all-marks" component={GlobalMarks} />*/}
            <Route path="/volunteers-history" component={VolunteersHistory} />
            <Route path="/denied" component={AccessDenied} />
            <Route path="*" component={NoMatch}/>
          </Switch>
        </main>
        <ToastContainer />
      </div>
  );
