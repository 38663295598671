import {
  DELETE_SELECTED_USER,
  DELETE_SELECTED_PARTICIPANT,
  PUSH_COLUMNS,
  PUSH_SELECTED_USER,
  SET_PARTICIPANTS_TABLE_DATA,
  CLEAR_PARTICIPANTS,
  CLEAR_COLUMNS,
  SELECT_COLUMN_TO_DISPLAY,
  DELETE_COLUMN_TO_DISPLAY,
  SET_COLUMNS_TO_DISPLAY,
  SET_ANOMALY_OF_REVIEWS,
  SET_USER_FIRST_ATTENDING,
} from "../actionTypes";
import NAV_BAR_ITEM_LIST from "../../config/tableColumns";
import {ActiveStudent, Student} from "../../react-app-env";
import { loadState } from "../../utils/localStorage";
import defaultColumns from "../../config/tableColumns/default";

interface TableAliases {
  [index: string]: Array<any>
}

const { tableColumns = {} } = (loadState() || {});

// @ts-ignore
const setDefaultTableAliases = (): TableAliases =>
  NAV_BAR_ITEM_LIST.reduce(
    (acc: TableAliases, { slug, columns }: {slug: string, columns: any}) => ({
      ...acc,
      [slug]: [...defaultColumns, ...columns]
        .filter((c) => (tableColumns[slug] || []).includes(c.id) || c.defaultVisibility)
        .map((c) => c.id),
    })
    , {} as TableAliases
  );

const initialState = {
  selectedUsers: [],
  participants: [] as Student[],
  columnsToDisplay: setDefaultTableAliases(),
  columns: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PUSH_SELECTED_USER: {
      const { selectedUsers } = action.payload;
      return {
        ...state,
        selectedUsers,
      };
    }
    case SET_PARTICIPANTS_TABLE_DATA: {
      const { participants } = action.payload;
      return {
        ...state,
        participants,
      };
    }
    case DELETE_SELECTED_USER: {
      const { email } = action.payload;

      return {
        ...state,
        selectedUsers: state.selectedUsers.filter((user) => email !== user),
      };
    }
    case SET_USER_FIRST_ATTENDING: {
      const id = action.payload;
      const participants = (state.participants as ActiveStudent[]).map((user: ActiveStudent): ActiveStudent => ({
        ...user,
        additional: {
          ...user.additional,
          attendFirstMeeting: user.id === id ? true : user.additional.attendFirstMeeting,
        },
      }));
      return {
        ...state,
        participants,
      };
    }
    case DELETE_SELECTED_PARTICIPANT: {
      const { id } = action.payload;
      return {
        ...state,
        participants: state.participants.filter((user) => user.id !== id),
      };
    }
    case DELETE_COLUMN_TO_DISPLAY: {
      const { id, slug } = action.payload;
      return {
        ...state,
        columnsToDisplay: {
          ...state.columnsToDisplay,
          [slug]: state.columnsToDisplay[slug].filter((columnId) => columnId !== id),
        },
      };
    }
    case PUSH_COLUMNS: {
      const { columns } = action.payload;

      return {
        ...state,
        columns,
      };
    }
    // case SET_All_USERS_MARK_DATA: {
    //   return {
    //     ...state,
    //     marksOfStudents: action.payload.data
    //   }
    // }
    case SET_ANOMALY_OF_REVIEWS: {
      return {
        ...state,
        anomalyOfMarks: action.payload,
      };
    }
    case SELECT_COLUMN_TO_DISPLAY: {
      const { columnId, slug } = action.payload;

      return {
        ...state,
        columnsToDisplay: {
          ...state.columnsToDisplay,
          [slug]: (state.columnsToDisplay[slug] && columnId) ? [...state.columnsToDisplay[slug], columnId] : [],
        },
      };
    }
    case SET_COLUMNS_TO_DISPLAY: {
      const { columnsToDisplay, slug } = action.payload;

      return {
        ...state,
        columnsToDisplay: {
          [slug]: columnsToDisplay,
        },
      };
    }
    case CLEAR_PARTICIPANTS: {
      return {
        ...state,
        participants: [],
      };
    }
    case CLEAR_COLUMNS: {
      return {
        ...state,
        columns: [],
      };
    }
    default:
      return state;
  }
}
