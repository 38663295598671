import { formatPhone } from "../../utils";
import {ActiveStudent, GraduatedStudent, PotentialStudent, Volunteer} from "../../react-app-env";

const convertActiveStudentsDataToFormat = (APIData) => APIData.map((student: any): ActiveStudent => {
  const { profile } = student;
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    birthday: profile.birthday,
    nickname: profile.nickname,
    city: profile.city,
    email: profile.email,
    phone: formatPhone(profile.phone),
    discord: profile.discord,
    avatar: profile.avatar,
    id: profile.id,
    additional: {
      ...student.progress,
      currentReview: student.review.current,
      futureReview: student.review.futureLevel,
      lastVisit: profile.lastVisitTs,
    },
  };
});

const convertPotentialStudentsDataToFormat = (APIData = {}) =>
  Object.keys(APIData).reduce((students: PotentialStudent[], key: string): PotentialStudent[] => {
    let typeToStart = "";
    if (key === "readyToStart") {
      typeToStart = "никогда не учился";
    } else if (key === "readyAfterDrop") {
      typeToStart = "был сброшен";
    } else if (key === "nearestStart") {
      typeToStart = "записан на старт";
    } else if (key === "notActual") {
      typeToStart = "не будет учиться";
    }
    return [
      ...students,
      ...APIData[key].map((potentialUser): PotentialStudent => ({
        id: potentialUser.id,
        firstName: potentialUser.firstName,
        nickname: potentialUser.nickname,
        lastName: potentialUser.lastName,
        birthday: potentialUser.birthday,
        city: potentialUser.city,
        email: potentialUser.contacts.email,
        phone: formatPhone(potentialUser.contacts.phone),
        discord: potentialUser.contacts.discord,
        additional: {
          entranceExamDate: potentialUser.entranceExamDate,
          typeOfStudent: typeToStart,
          startDate: potentialUser.startDate,
          eduAttempts: potentialUser.eduAttempts
        },
        avatar: potentialUser.avatar
      }))
    ];
  }, [] as PotentialStudent[]);

const convertVolunteersDataToFormat = (APIData) => APIData.map((volunteer: any): Volunteer => ({
  id: volunteer.id,
  firstName: volunteer.firstName,
  lastName: volunteer.lastName,
  nickname: volunteer.nickname,
  birthday: volunteer.birthday,
  city: volunteer.city,
  email: volunteer.contacts.email,
  phone: formatPhone(volunteer.contacts.phone),
  discord: volunteer.contacts.discord,
  avatar: volunteer.avatar,
  additional: {
    lastVisitTs: volunteer.lastVisitTs,
    level: volunteer.level,
    currentReview: volunteer.review.current,
    futureReview: volunteer.review.future,
    reviewsAmount: volunteer.reviewsAmount,
    bestReviewerRating: volunteer.bestReviewerRating
  },
}));

const convertGraduatedStudentsToFormat = (APIData) => APIData.map((graduate: any): GraduatedStudent => ({
  id: graduate.id,
  firstName: graduate.firstName,
  lastName: graduate.lastName,
  nickname: graduate.nickname,
  birthday: graduate.birthday,
  city: graduate.city,
  email: graduate.contacts.email,
  phone: formatPhone(graduate.contacts.phone),
  discord: graduate.contacts.discord,
  avatar: graduate.avatar,
  additional: {
    mentorCourseOpened: graduate.mentorCourseOpened,
    graduateDate: graduate.graduateDate,
    homeWorkUrl: graduate.homeworksUrl,
    bestReviewerRating: graduate.bestReviewerRating,
    reviewsAmountAsReviewer: graduate.reviewsAmountAsReviewer,
    inflatedMarksAmount: graduate.inflatedMarksAmount,
    eduAttempts: graduate.eduAttempts,
    avgMarks: {
      general: graduate.avgMarks.general,
      reviewer: graduate.avgMarks.reviewer,
      examinee: graduate.avgMarks.examinee,
    },
  },
}));

export default (APIData, slug) => {
  if (slug === "potential") {
    return convertPotentialStudentsDataToFormat(APIData);
  }
  if (slug === "current") {
    return convertActiveStudentsDataToFormat(APIData);
  }
  if (slug === "volunteers") {
    return convertVolunteersDataToFormat(APIData);
  }
  if (slug === "graduates") {
    return convertGraduatedStudentsToFormat(APIData);
  }
  return null;
};
