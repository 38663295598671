import React from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bulma-components";
import {fetchDropToCurrentLevel, fetchDropUserFromStudy, fetchParticipants} from "../redux/inquiries";
import {RouteComponentProps, withRouter} from "react-router";

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {
  type: string
}

type DropUserModalProps = RouteComponentProps<PathParamsType> & {
  data,
  close: () => void,
  fetchParticipants: (slug: string) => Promise<void>
}

type DropUserModalState = {
  dropReason: string,
  cheater: boolean,
  ban: boolean,
  level: number | null,
}

class DropUserModal extends React.Component<DropUserModalProps, DropUserModalState> {
  constructor(props) {
    super(props);
    this.state = {
      dropReason: "",
      cheater: false,
      ban: false,
      level: null,
    };
  }

  async dropUserFromStudying() {
    if (this.state.dropReason.length === 0) {
      alert("пожалуйста, укажите причину");
      return
    }
    await fetchDropUserFromStudy({
      userId: this.props.data.id,
      dropTo: this.state.level,
      dropReason: this.state.dropReason,
      isCheater: this.state.cheater,
      ban: this.state.ban
    });

    this.setState({ dropReason: "", cheater: false, ban: false, level: null});
    await this.props.fetchParticipants(this.props.match.params.type);
    this.props.close();
  };

  async dropUserFromReview() {
    await fetchDropToCurrentLevel(this.props.data.id);
    await this.props.fetchParticipants(this.props.match.params.type);
    this.props.close();
  };

  render() {
    // const { cheater } = this.state;
    return (
      <Modal.Card>
        <Modal.Card.Head onClose={this.props.close} showClose>
          <Modal.Card.Title>
            {this.props.data.actionType === "dropFromReview"
              ? "Вы уверены что хотите скинуть с ревью "
              : "Вы уверены что хотите скинуть с учёбы "}
            <b>{this.props.data.firstName} {this.props.data.lastName}</b>?
          </Modal.Card.Title>
        </Modal.Card.Head>
        <Modal.Card.Body>
          {this.props.data.actionType === "dropFromStudy"
            ? (<>
              <div className="field">
                <label htmlFor="drop-to-level"><b>Уровень на который сбросить</b> (необязательно)</label>
                <div className="control">
                  <input
                    id="drop-to-level"
                    className="input"
                    type="number"
                    onChange={(e) => this.setState({level: +e.target.value})}
                  />
                  <p className="help">
                    Пишите номер того уровня, который будет <em>последний закрытый</em> у студента.<br/>
                    {this.state.level
                      ? <span>При таких данных, в <em>ближайшее воскресенье</em> студенту откроется <strong>{+this.state.level+1} уровень</strong>.</span>
                      : ""}
                  </p>
                </div>
              </div>
              <div className="field">
                <label htmlFor="drop-to-level"><b>Причина сброса</b></label>
                <div className="control">
                  <input
                    id="drop-reason"
                    className="input"
                    type="text"
                    onChange={(e) => this.setState({dropReason: e.target.value})}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                  <input
                    id="cheater"
                    type="checkbox"
                    onChange={(e) => this.setState({cheater: e.target.checked})}
                  />
                  дроп за читерство
                  </label>
                  {this.state.cheater &&
                  <p className="help">
                    Студент будет сброшен в самое начало
                  </p>
                  }
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                  <input
                    id="ban"
                    type="checkbox"
                    onChange={(e) => this.setState({ban: e.target.checked})}
                  />
                  забанить
                  </label>
                </div>
              </div>
            </>)
            : null
          }
        </Modal.Card.Body>
        <Modal.Card.Foot style={{ alignItems: "end", justifyContent: "end" }}>
          <Button onClick={() => this.props.close()}>
          Отмена
          </Button>
          <Button
            color="danger"
            onClick={async () => {
              this.props.data.actionType === "dropFromReview"
                ? await this.dropUserFromReview()
                : await this.dropUserFromStudying();
            }}
          >
            {this.props.data.actionType === "dropFromReview"
              ? "Сбросить с ревью"
              : "Сбросить с учёбы"}
          </Button>
        </Modal.Card.Foot>
      </Modal.Card>
    );
  }
}

const mapStateToProps = (state) => ({
  columns: state.users.columns,
  columnsToDisplay: state.users.columnsToDisplay,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchParticipants
  })(DropUserModal)
);
