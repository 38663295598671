export const loadState = () => {
  const serializedState = localStorage.getItem("tableColumns");
  if (serializedState === null) {
    // Redux requires it to be undefined
    return undefined;
  }
  return JSON.parse(serializedState);
};

export const saveState = (state) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem("tableColumns", serializedState);
};
