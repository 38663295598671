import React from "react";
import { Button, Modal } from "react-bulma-components";
import {deleteSelectedUser, notifyWithErrors} from "../redux/actions";
import {connect} from "react-redux";

type SendEmailsModalProps = {
  emails: string[],
  close: () => void,
  deleteSelectedUser: (email: string) => void
}

type SendEmailsModalState = {
  message: string,
}


class SendEmailsModal extends React.Component<SendEmailsModalProps, SendEmailsModalState> {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
  }

  handleChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  handleMessageSubmit = () => {
    const errors = this.hasValidationErrors();
    if (errors) {
      notifyWithErrors(errors);
    }
  };

  hasValidationErrors() {
    const errors = [] as string[];
    const { emails } = this.props;
    const { message } = this.state;
    if (!emails.length) {
      errors.push("Выберите получателей письма");
    }
    if (!message || !message.length) {
      errors.push("Введите текст письма");
    }
    if (errors.length) {
      return errors;
    }
    return false;
  }

  render() {
    const userEmails = this.props.emails.map((email) => (
      <span key={email} className="tag is-light">
        {email}
        <button
          className="delete is-small"
          type="button"
          onClick={() => this.props.deleteSelectedUser(email)}
        />
      </span>
    ));

    return (
      <Modal.Card>
        <Modal.Card.Head onClose={this.props.close}>
          <Modal.Card.Title>
            Отправка писем
          </Modal.Card.Title>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <div>{userEmails.length ? userEmails : "Список получателей - пуст."}</div>
          <textarea
            className="textarea"
            placeholder="Введите текст сообщения..."
            rows={10}
            onChange={this.handleChange}
          />
        </Modal.Card.Body>
        <Modal.Card.Foot>
          <Button
            color="black"
            className="m-t-md m-b-md"
            onClick={this.handleMessageSubmit}
          >
            Отправить
          </Button>
        </Modal.Card.Foot>
      </Modal.Card>
    );
  }
}
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  deleteSelectedUser,
})(SendEmailsModal);
