import React from "react";
import links from "../config/links";

export default () => {
  setTimeout(() => {
    window.location.href = links.SIGN_IN;
  }, 500);
  return (
    <div>
      <h3 className="title centered is-3">
      У Вас нет доступа на посещение данной страницы
      </h3>
    </div>
  );
};
