import {accounts, api, p2p} from "../config/api";
import {
  changeFirstAttendingType,
  pushColumns,
  setAnomaly,
  setGroupStartDates,
  setMarkData,
  setReviewPlan, setReviewPlanWeek,
  setTableData,
} from "./actions";
import chooseDataTypeFormatForTable from "./reducers/formatDataToTableStandart";
import NAV_BAR_ITEM_LIST from "../config/tableColumns";

export const getReviewSnapshots = () => async (dispatch) => {
  dispatch(setReviewPlan(await api.get("get_review_plan")));
};
export const getReviewSnapshot = (date) => async (dispatch) => {
  dispatch(setReviewPlanWeek(await api.get("get_review_plan_by_week", {date})));
};

export const getReviewRecommendation = (params) => async () => api.post("get_review_recommendation", params);

export const saveReviewSnapshot = (data) => async () => api.post("save_review_plan", data);

export const acceptUserToStudying = (userId: number) => async () => p2p.post("accept_to_started_group", {userId});


export const logInAsUser = ({email}) => {
  accounts.post("login_as_user", {email})
    .then(() => {
      window.location.href = process.env.REACT_APP_P2P_FRONT_URL || "";
    });
};
export const getGroupStartDates = () => async (dispatch) => dispatch(setGroupStartDates(await p2p.get("get_groups")));

export const saveGroupStartDates = (data) => async () => p2p.post("set_groups", data);

export const fetchParticipants = (slug) => (dispatch) => api.get(slug)
  .then((res) => {
    dispatch(setTableData(chooseDataTypeFormatForTable(res, slug)));
    const {columns} = NAV_BAR_ITEM_LIST.find((x) => x.slug === slug) || {columns: null};
    if (columns && columns.length > 0) {
      dispatch(pushColumns(columns));
    }
  });

export const fetchDropUserFromStudy = (params) => p2p.post("drop_user", params);

export const fetchAddUserToReview = (params) => p2p.post("add_to_review", params);

export const setUserWarningsLimit = (params) => async () => p2p.post("set_warnings_limit", params);

export const setUserActuality = (params) => async () => p2p.post("set_actuality", params);

export const fetchDropToCurrentLevel = (id) => p2p.post("remove_user_from_review", {userId: id});

export const fetchAnomalyData = () => async (dispatch) => dispatch(setAnomaly(await api.get("anomaly")));

export const fetchAttendFirstMeeting = (id) => (dispatch) => api.post("attend_first_meeting", {userId: id})
  .then(() => dispatch(changeFirstAttendingType(id)));

export const fetchSetTableData = () => async (dispatch) => dispatch(setMarkData(await api.get("marks")));

export const getUserLogs = (params): Promise<{ user: any, logs: any }> => api.get("logs", params) as any;
