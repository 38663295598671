export default [
  {
    id: "additional.entranceExamDate",
    defaultVisibility: true,
    Header: "Дата теста",
    width: 100,
    accessor: "additional.entranceExamDate",
  },
  {
    id: "additional.eduAttempts",
    defaultVisibility: true,
    Header: "Стартовал",
    width: 80,
    accessor: 'additional.eduAttempts',
    Cell: ({original: {additional}}) => `${additional.eduAttempts || 0} раз`,
  },
  {
    Header: "Статус",
    id: "typeForWaiting",
    accessor: "additional.typeOfStudent",
    width: 150,
    defaultVisibility: true,
  },
  {
    Header: "Дата начала",
    defaultVisibility: true,
    id: "startDate",
    accessor: "additional.startDate",
    Cell: ({original}) => (original.additional.startDate
      ? new Date((original.additional.startDate) * 1000).toLocaleDateString()
      : 0),
    maxWidth: 100,
  },
];
