import { SET_GROUPS } from "../actionTypes";

const initialState = {
  startDates: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GROUPS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
