import React from "react";

export const Availability = (review) => (
  <div>
    {
      review && review.availability
        ? (
          <div
            className="tooltip is-tooltip-bottom is-tooltip-multiline"
            data-tooltip={review.availability}
            style={{ height: "50%" }}
          >
            { +!!review.availability }
          </div>
        )
        : null
    }
  </div>
);

export default [
  {
    id: "startStudyDate",
    defaultVisibility: true,
    width: 90,
    Header: "Дата старта",
    accessor: "additional.startStudyDate",
    Cell: ({original}) => new Date(original.additional.startStudyDate * 1000).toLocaleDateString(),
  },
  {
    id: "level",
    defaultVisibility: true,
    Header: "level",
    accessor: "additional.level",
    className: "has-text-right",
    width: 45,
  },
  {
    id: "reviewRegistrationDeadline",
    defaultVisibility: true,
    width: 90,
    Header: "Дедлайн дз",
    accessor: "additional.reviewRegistrationDeadlineTS",
    Cell: ({ original }) => new Date(original.additional.reviewRegistrationDeadlineTS * 1000).toLocaleDateString(),
  },
  {
    id: "marks",
    defaultVisibility: true,
    width: 100,
    accessor: 'additional.avgMark.general',
    Header: "Оценки",
    Cell: ({original}) => (
      <div
        className="tooltip is-tooltip-bottom"
        data-tooltip="за код/как ревьюверу/как проверяемому"
      >{original.additional.avgMark.general || 0}/{original.additional.avgMark.reviewer || 0}/{original.additional.avgMark.examinee || 0}</div>
    ),
  },
  {
    id: "visible-warnings",
    defaultVisibility: true,
    Header: "Вид предупр",
    width: 60,
    Cell: ({original}) => (
      <div
        className="tooltip is-tooltip-bottom"
        data-tooltip="получено/доступно"
      >{original.additional.warnings.visible || 0}/{original.additional.warnings.limit}</div>
    ),
    accessor: "additional.warnings.visible",
    className: "has-text-right",
  },
  {
    id: "hidden-warnings",
    defaultVisibility: true,
    Header: "Невид предупр",
    width: 60,
    accessor: "additional.warnings.hidden",
    className: "has-text-right",
  },
  {
    id: "bestReviewerRating",
    defaultVisibility: true,
    Header: "Лучший ревьювер",
    width: 100,
    accessor: ({additional}) => 100 * additional.bestReviewerRating / (additional.reviewsAmountAsReviewer || 1),
    Cell: ({original: {additional}}) => (
      <div
        className="tooltip is-tooltip-bottom"
        data-tooltip="лучший ревьювер / всего ревью / соотношение"
      >
        {additional.bestReviewerRating || 0}&nbsp;/&nbsp;
        {additional.reviewsAmountAsReviewer || 0}&nbsp;/&nbsp;
        {~~(100 * additional.bestReviewerRating / (additional.reviewsAmountAsReviewer || 1))}%
      </div>
    ),
    className: "has-text-right",
  },
  {
    id: "additional.inflatedMarksAmount",
    defaultVisibility: true,
    Header: "Был добряком",
    width: 80,
    accessor: 'additional.inflatedMarksAmount',
    Cell: ({original: {additional}}) => `${additional.inflatedMarksAmount || 0} раз`,
  },
  {
    id: "additional.eduAttempts",
    defaultVisibility: true,
    Header: "Стартовал",
    width: 80,
    accessor: 'additional.eduAttempts',
    Cell: ({original: {additional}}) => `${additional.eduAttempts || 0} раз`,
  },
  {
    id: "additional.lastVisit",
    defaultVisibility: true,
    Header: "Останній вхід",
    width: 90,
    accessor: 'additional.lastVisit',
    Cell: ({original: {additional}}) => additional.lastVisit ? `${new Date(additional.lastVisit * 1000).toLocaleDateString()}` : '',
  },
  {
    Header: "Текущее ревью",
    defaultVisibility: false,
    id: "currentReview",
    columns: [
      {
        id: "given-reviews-progress",
        Header: "Прогресс провед ревью",
        accessor: ({additional}) => (additional.currentReview && additional.currentReview.given ? `${additional.currentReview.given.total}/${additional.currentReview.given.inProgress}/${additional.currentReview.given.finished}` : null),
      },
      {
        id: "received-reviews-progress",
        Header: "Прогресс получ ревью",
        accessor: ({additional}) => (additional.currentReview && additional.currentReview.received ? `${additional.currentReview.received.total}/${additional.currentReview.received.inProgress}/${additional.currentReview.received.finished}` : null),
      },
      {
        id: "review-availability",
        Header: "Доступн",
        Cell: ({ original }) => Availability(original.additional.currentReview),
      },
    ],
  },

  {
    Header: "Будущее ревью",
    defaultVisibility: false,
    id: "futureLevel",
    columns: [
      {
        id: "futureLevel-homeworkUploaded",
        Header: "Д/з загружено",
        accessor: "additional.futureReview.homeworkUploaded",
        className: "has-text-right",
        Cell: ({ original }) => +((original.additional.futureReview || {}).homeworkUploaded || 0),
      },
      {
        id: "futureLevel-confirmed",
        Header: "Подтвердил",
        accessor: "additional.futureReview.confirmed",
        Cell: ({ original }) => +((original.additional.futureReview || {}).confirmed || 0),
      },
      {
        id: "futureLevel-availability",
        Header: "Доступность",
        accessor: "additional.futureReview.availability",
        Cell: ({ original }) => Availability(original.additional.futureReview),
      },
    ],
  },

];
