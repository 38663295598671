import React from "react";
import { Button, Modal } from "react-bulma-components";
import { connect } from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import DataTable from "../components/DataTable";
import TableColumnsDisplay from "../components/TableColumnsDisplay";
import {clearParticipants} from "../redux/actions";
import "react-toastify/dist/ReactToastify.css";
import { fetchParticipants, getGroupStartDates } from "../redux/inquiries";
import SendEmailsModal from "../components/ModalSendEmails";
import store from "../redux/store";

type PathParamsType = {
  type: string
}

type StudentsProps = RouteComponentProps<PathParamsType> & {
  selectedUsers,
  fetchParticipants: (slug: string) => Promise<void>,
  getGroupStartDates: () => void
}

type StudentsState = {
  show: boolean
}

class Students extends React.Component<StudentsProps, StudentsState> {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  async componentDidMount() {
    this.getAllData();
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      await this.getAllData();
    }
  }
  async getAllData() {
    const { type } = this.props.match.params;
    await this.props.fetchParticipants(type);
    if (type === "potential") {
      await this.props.getGroupStartDates()
    }
  }

  componentWillUnmount() {
    store.dispatch(clearParticipants());
  }

  open() {
    this.setState({show: true});
  }

  close() {
    this.setState({show: false});
  }

  render() {
    const { selectedUsers } = this.props;
    const { show } = this.state;

    return (
      <div className="user-message-center">
        <TableColumnsDisplay />
        {selectedUsers.length
          ? (
            <Button color="black" className="m-t-md m-b-md" onClick={this.open.bind(this)}>
            Отправить отмеченным письмо
            </Button>
          )
          : null}
        <Modal
          closeOnBlur
          show={show}
          onClose={this.close.bind(this)}
        >
          <SendEmailsModal emails={selectedUsers} close={this.close.bind(this)} />
        </Modal>

        <DataTable />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedUsers: state.users.selectedUsers,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchParticipants,
    getGroupStartDates
  })(Students),
);
