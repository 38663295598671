import React from "react";

import {RouteComponentProps, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-bulma-components";

import {
  getReviewRecommendation,
  getReviewSnapshots,
  getReviewSnapshot,
  saveReviewSnapshot,
} from "../redux/inquiries";
import {
  augmentAndFillArray,
  decodeHTMLText,
  mergeObjectFields,
} from "../utils";
import {
  formatSnapshot,
  getStudentsWithFilledExaminees,
  getStudentsWithHighlightedCrossReview,
  getStudentsWithHighlightedLevelDeference,
  getStudentsWithHighlightedReviewersSufficiency,
  getStudentsWithHighlightedSelfReview,
  refactorSnapshot,
  restoreParticipants,
  call,
} from "../utils/reviewPlanner";

interface Participant {
  level: number,
  id: number,
  _notEnoughReviewers: boolean,
  _volunteer: boolean,
  preferredLevel: number,
  _examinees
}

interface Snapshot {

}

type PathParamsType = {}

type ReviewPlanningProps = RouteComponentProps<PathParamsType> & {
  snapshots,
  users,
  reviewsAmount,
  reviewersAmount: {
    min: number,
    max: number
  },
  getReviewSnapshots: () => Promise<void>,
  getReviewSnapshot: (weekIndex: string) => Promise<void>,
  getReviewRecommendation: ({
    snapshot: Snapshot
  }) => Promise<void>,
  saveReviewSnapshot: ({
    snapshot: Snapshot,
    reviewDateTs: number
  }) => Promise<void>
};

type ReviewPlanningState = {
  selectedSnapshotIndex: number,
  selectedReviewers: any,
  plan: {
    participants: Participant[],
    date: string
  }
};


class ReviewPlanning extends React.Component<ReviewPlanningProps, ReviewPlanningState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedSnapshotIndex: 0,
      selectedReviewers: {} as any,
      plan: {
        participants: [],
        date: "0"
      },
    };
  }

  async componentDidMount() {
    await this.props.getReviewSnapshots();

    await this._setSelectedReviewers();
    this._augmentThisWeekParticipants();
  }

  _selectReviewer(examineId, reviewerIndex, newReviewerId) {
    // i hate react when i have to do such a strange manipulation 😡
    const { selectedReviewers } = this.state;
    const newReviewersList = [...selectedReviewers[examineId]];
    newReviewersList.splice(reviewerIndex, 1, +newReviewerId);

    this.setState({
      selectedReviewers: {
        ...selectedReviewers,
        [examineId]: newReviewersList,
      },
    },
    () => {
      this._augmentThisWeekParticipants();
    });
  }

  _kickExamine(examineId, reviewerId) {
    // i hate react when i have to do such a strange manipulation 😡
    const { selectedReviewers } = this.state;
    const newReviewersList = [...selectedReviewers[examineId]];
    const reviewerIndex = newReviewersList.indexOf(+reviewerId);
    newReviewersList.splice(reviewerIndex, 1);

    const { min, max } = this.props.reviewersAmount;
    this.setState({
      selectedReviewers: {
        ...selectedReviewers,
        [examineId]: augmentAndFillArray(newReviewersList, min, max, examineId),
      },
    },
    () => {
      this._augmentThisWeekParticipants();
    });
  }

  _getReviewSnapshot(reviewDate: string) {
    return this.props.getReviewSnapshot(reviewDate);
  }
  _selectWeek(weekIndex: number) {
    this._getReviewSnapshot(this.props.snapshots[weekIndex].date)
      .then(() => {
        this.setState(
          { selectedSnapshotIndex: weekIndex },
          async() => {
            await this._setSelectedReviewers();
            this._augmentThisWeekParticipants();
          },
        );
      })

  }

  // create an object of format
  // {  [examineId]: [reviewerId, reviewerId], ... }
  async _setSelectedReviewers(snapshot?) {
    return new Promise((resolve) => {
      this.setState({ selectedReviewers: {} }, ()=> {
        const { participants } = snapshot || this.props.snapshots[this.state.selectedSnapshotIndex] || { participants: [] };
        const { min, max } = this.props.reviewersAmount;
        const selectedReviewers = participants.reduce(
          (acc, { id, reviewers = [], level }) => ({
            ...acc,
            // create a new empty array of maximal length to make sure we always have enough slots, but not too much
            // and fill it with the reviewers' we have ids , or the examine's
            // [{id: number, level: string, preferredLevel: string}]
            [id]: level ? augmentAndFillArray(reviewers.map((r) => r.id), min, max, id) : [],

          }),
          {},
        );
        this.setState({ selectedReviewers }, () => resolve());
      })
    })
  }

  _augmentThisWeekParticipants(snapshot?) {
    const participants = restoreParticipants(this.state.selectedReviewers, snapshot || this.props.snapshots[this.state.selectedSnapshotIndex]);
    call(
      participants,
      getStudentsWithHighlightedReviewersSufficiency,
      "participants",
      this.props.reviewersAmount.min,
    )
      .then((res) => call(res, getStudentsWithFilledExaminees))
      .then((res) => call(res, getStudentsWithHighlightedSelfReview))
      .then((res) => call(res, getStudentsWithHighlightedCrossReview))
      .then((res) => call(res, getStudentsWithHighlightedLevelDeference))
      .then((plan) => this.setState({ plan }));
  }

  _saveToJSON() {
    function download(content, fileName, contentType) {
      const a = document.createElement("a");
      const file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    download(JSON.stringify({
      participants: this.state.plan.participants,
      selectedReviewers: this.state.selectedReviewers,
      snapshotDate: this.state.plan.date,
    }, null, 2), `snapshot-${this.state.plan.date}.json`, "application/json");
  }

  _readFromJSON() {
    const file = (document.getElementById("snapshot-file") as any).files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = (evt) => {
        const { snapshotDate, selectedReviewers, participants } = JSON.parse(`${(evt.target as any).result}`);
        const { plan } = this.state;
        this.setState({
          plan: {
            ...plan,
            participants,
            date: snapshotDate,
          },
          selectedReviewers,
          selectedSnapshotIndex: this.props.snapshots.findIndex((s) => s.date === snapshotDate),
        }, () => alert("загружено и применено успешно"));
      };
      reader.onerror = () => alert("проблема при чтении файла");
    }
  }

  async _generatePlan() {
    let snapshot = await this.props.getReviewRecommendation({
      snapshot: formatSnapshot(this.state.plan.participants, this.state.selectedReviewers, this.state.plan.date),
    });
    snapshot = refactorSnapshot(snapshot);
    snapshot = mergeObjectFields(snapshot, "participants", "students", "volunteers");
    await this._setSelectedReviewers(snapshot);
    this._augmentThisWeekParticipants(snapshot);
  }

  async _save() {
    await this.props.saveReviewSnapshot({
      snapshot: formatSnapshot(this.state.plan.participants, this.state.selectedReviewers),
      reviewDateTs: +(new Date(this.state.plan.date)) / 1000,
    });
    alert("Сохранено");
  }

  render() {
    const { snapshots, users, reviewsAmount } = this.props;
    const { plan } = this.state; // snapshots[this.state.selectedSnapshotIndex];
    const reviewsAmountForSelectedWeek = reviewsAmount[this.state.selectedSnapshotIndex];

    const participantContact = (p) => {
      const contact = users[p.id];
      if (!contact) return `Неизвестный юзер (id: ${p.id})`;
      return decodeHTMLText(`${contact.lastName} ${contact.firstName} (${contact.city})`);
    };

    // minus 1 because of +1 in getCalculatedStudentsReviews at the most deep line
    const getGivenReviewAmount = (x, y) => ((reviewsAmountForSelectedWeek[x] || {})[y] || 0);

    const reviewersSelect = ({ id: userId, level: userLevel }, i) => (
      <div key={`${i}${userId}`}>
        <select
          value={this.state.selectedReviewers[userId][i]}
          onChange={(e) => this._selectReviewer(userId, i, e.target.value)}
          disabled={plan.date !== (snapshots[0] || {}).date}
        >
          {
            plan.participants
              // filtering by reviewer.level >= user.level except volunteers
              // if no level it is a volunteer
              .filter(({ level }) => level >= userLevel)
              // sorting by level , not sure if it will be really useful
              .sort((a, b) => a.level - b.level)
              .map((potentialReviewer) => (
                <option
                  key={`${potentialReviewer.id}${i}${userId}`}
                  value={potentialReviewer.id}
                >
                  {participantContact(potentialReviewer)},
                  {potentialReviewer._volunteer ? ", волонтёр" : `, уровень ${potentialReviewer.level}`},
                  ревьювался {getGivenReviewAmount(potentialReviewer.id, userId)} раз,
                  ревьювал {getGivenReviewAmount(userId, potentialReviewer.id)} раз
                </option>
              ))
          }
        </select>
      </div>
    );

    if (!snapshots.length) {
      return <div className="container">Нет ни одной недели для планирования</div>;
    }
    if (!plan || !plan.participants) {
      return <div className="container">Нет участников на эту неделю...</div>;
    }
    return (
      <div className="container">
        <div className="buttons">
          <Form.Field horizontal>
            <Form.Label>Определение ревьюверов на:</Form.Label>
            <Form.Control>
              <select
                id="snapshot-date"
                value={this.state.selectedSnapshotIndex}
                onChange={(e) => this._selectWeek(+e.target.value)}
              >
                {snapshots.map((s, i) => <option value={i} key={i}>{s.date}</option>)}
              </select>
            </Form.Control>
          </Form.Field>
          &nbsp;
          <button type="button" className="button is-black" onClick={() => this._generatePlan()}>генерировать</button>
          <button type="button" className="button is-black" onClick={() => this._save()}>сохранить</button>
          <button type="button" className="button" onClick={() => this._saveToJSON()}>скачать результат в json-формате</button>
          <button type="button" className="button" onClick={() => this._readFromJSON()}>
            <label className="file-label" htmlFor="snapshot-file">
              <input className="file-input" type="file" id="snapshot-file" onChange={() => this._readFromJSON()} />
              <span className="file-label">
                загрузить план из json файла
              </span>
            </label>
          </button>
        </div>
        {[...plan.participants]
          .sort((a, b) => b._volunteer ? -1 : (a.level - b.level))
          .map((student) => (
          <div className="columns" key={student.id}>
            <div className="column">
              <p>
                <b>
                  {student._notEnoughReviewers ? `❗` : ``}
                  {participantContact(student)}
                  {student._volunteer ? ", волонтёр" : `, уровень ${student.level}`}
                  {student._volunteer && student.preferredLevel ? ", 🔍 lvl " + student.preferredLevel : ""}
                  {student._volunteer && student.preferredLevel && student._examinees.length > 1 && (
                    student._examinees.filter(s => !s._selfReview).every(({level}) => level === student.preferredLevel) ? " ✓" : " ❌"
                  )}
                </b>
              </p>
              {
                !student._volunteer
                && (this.state.selectedReviewers[student.id] || [])
                  .map((_, i) => reviewersSelect(student, i))
              }
            </div>
            <div className="column">
              {/* TODO: highlight this block if examinees.length is more than 3 */}
              <p><b>Смотрит код этих людей ({(student._examinees || []).length - (+student._volunteer || 0)} чел.):</b></p>
              <ol>
                {
                  (student._examinees || [])
                    .filter(({id}) => student._volunteer ? student.id !== id : true)
                    .map(
                    (examine) => (
                      <li
                        key={`${examine.id}${student.id}`}
                      >
                        {examine._crossReview && "🔁"}
                        {examine._selfReview && "🔄"}
                        {examine._hasDifferentLevel && "👶🏻"}
                        {participantContact(examine)}
                        {', уровень ' + examine.level}
                        {plan.date === (snapshots[0] || {}).date
                          ? (
                            <button
                              type="button"
                              className="delete is-small"
                              onClick={() => this._kickExamine(examine.id, student.id)}
                            />
                          )
                          : null}
                      </li>
                    ),
                  )
                }
              </ol>
            </div>
          </div>
        ))}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.review,
});


export default withRouter(
  connect(mapStateToProps, {
    getReviewSnapshots,
    getReviewSnapshot,
    saveReviewSnapshot,
    getReviewRecommendation,
  })(ReviewPlanning),
);
