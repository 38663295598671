import React from "react";
import {Availability} from "./current";

export default [
  {
    Header: "level",
    id: "level",
    accessor: "additional.level",
    maxWidth: 50,
  },
  {
    id: "bestReviewerRating",
    defaultVisibility: true,
    Header: "Лучший ревьювер",
    width: 110,
    accessor: ({additional}) => 100 * additional.bestReviewerRating / (additional.reviewsAmount || 1),
    Cell: ({original: {additional}}) => (additional.bestReviewerRating || 0) + " / "
      + (additional.reviewsAmount || 0) + " / "
      + (~~(100 * additional.bestReviewerRating / (additional.reviewsAmount || 1))) + "%",
    className: "has-text-right",
},
  {
    Header: "текущее ревью",
    id: "currentReview",
    columns: [
      {
        id: "examineeAmount",
        Header: "Кол-во провер",
        accessor: "additional.currentReview.examineeAmount",
        Cell: ({original}) => (original.additional.currentReview ? original.additional.currentReview.examineeAmount : ''),
        className: "has-text-right",
      },
      {
        id: "availability",
        Header: "Доступн",
        accessor: (d) => Availability(d.additional.currentReview),
      },
      {
        id: "currentPreferredLevel",
        Header: "Жел. уровень",
        accessor: "additional.currentReview.preferredLevel",
        className: "has-text-right",
      },
    ],
  },
  {
    Header: "Будущее ревью",
    id: "futureReview",
    columns: [
      {
        id: "confirmed",
        Header: "Участвует",
        accessor: (d) => +(d.additional.futureReview || {}).confirmed || '',
      },
      {
        id: "availability",
        Header: "Доступн",
        accessor: (d) => Availability(d.additional.futureReview),
      },
      {
        id: "futurePreferredLevel",
        Header: "Жел. уровень",
        accessor: "additional.futureReview.preferredLevel",
        className: "has-text-right",
      },
    ],
  },
  {
    Header: "Послед посещ",
    id: "lastVisit",
    accessor: "additional.lastVisitTs",
    Cell: ({original}) => new Date(original.additional.lastVisitTs * 1000).toLocaleDateString(),
    width: 80,
  },
];
