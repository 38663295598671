import axios from "axios";
import { toast } from "react-toastify";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";

export const Instance = (config) => {
  const instance = axios.create({
    baseURL: config.API_URL,
    withCredentials: true,
  });

  loadProgressBar(null, instance);

  instance.interceptors.request.use((conf) => conf);
  instance.interceptors.response.use(
    (response) => {
      if (response.config.method === 'post') {
        toast('Сохранено!')
      }
      return response
    },
    (error) => {
      if (error.response.data && error.response.data.error) {
        switch (error.response.data.error.code) {
          // todo: wrap numbers in nice constants
          case 401: // unauthorized
            window.location.href = process.env.REACT_APP_SIGN_IN_URL || "";
            return;
          case 403: // access denied
            window.location.replace("/denied");
            return;
          case 404:
            toast.error(error.response.data.error.message);
        }
      }
    },
  );

  return {
    async get<T = any>(what: string, params?) {
      const response = await instance.get<{data: T}>(config.endpoints[what], { params });
      return response.data.data
    },
    async post(what: string, params?) {
      const response = await instance.post<{data: any}>(config.endpoints[what], params);
      return response.data.data
    },
  };
};

export default { Instance };
