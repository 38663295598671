import { Instance } from "./api_instance";

export const api = Instance({
  API_URL: `${process.env.REACT_APP_API_URL}/ptp/admin/`,
  endpoints: {
    potential: "users/potential",
    current: "users/active",
    volunteers: "users/volunteer",
    graduates: "users/graduated",
    anomaly: "test.json",
    marks: "marks",
    get_review_plan: "review/snapshot/list",
    get_review_plan_by_week: "review/snapshot",
    get_review_recommendation: "review/recommend",
    save_review_plan: "review/snapshot/save",
    attend_first_meeting: "meeting/first/attend",
    logs: "logs",
    volunteers_history: "volunteers/reviews"
  },
});

export const accounts = Instance({
  API_URL: `${process.env.REACT_APP_ACCOUNTS_API_URL}`,
  endpoints: {
    login_as_user: "/login/as/user",
  },
});

export const p2p = Instance({
  API_URL: `${process.env.REACT_APP_P2P_API_URL}/ptp/admin/`,
  endpoints: {
    get_groups: "study/schedule/list",
    set_groups: "study/schedule/save",
    drop_user: "user/study/drop",
    remove_user_from_review: "user/review/delete",
    accept_to_started_group: "user/study/append",
    add_to_review: "user/review/append",
    set_warnings_limit: "user/warnings/limit/update",
    set_actuality: "user/actuality/set"
  },
});

export default { api, p2p };
