
//= ======================= some nice algorithms

import { arrayToObject, objectToArray, reviewersObjectToArray } from "./index";

export const call = async (week, func, key = "participants", ...params) => ({
  ...week,
  [key]: func(week[key], params),
});

/**
 * Defines whether the students have different level with their reviewers and returns a new object,
 *   which is the original one, augmented with a field hasDifferentLevel for each reviewer
 * @param students - Object of type {[studentID]: {level: number, reviewers: [{id: number, level: number}]}}
 * @return object, the same as students,
 * but with an additional field (hasDifferentLevel) for reviewers
 */
export const getStudentsWithHighlightedLevelDeference = (students) => students.map(
  (student) => ({
    ...student,
    _examinees: student._examinees
      .map(
        (examine) => ({
          ...examine,
          _hasDifferentLevel: +student.level !== +examine.level,
        }),
      ),
  }),
);

export const getStudentsWithHighlightedCrossReview = (students) => students.map(
  (student) => ({
    ...student,
    _examinees: student && student._examinees
      .map((examine) => {
        const examineAsStudent = students.find((rr) => rr.id === examine.id) || { _examinees: [] };
        return {
          ...examine,
          _crossReview: !!examineAsStudent._examinees.find((r) => +r.id === +student.id),
        };
      }),
  }),
);

export const getStudentsWithHighlightedReviewersSufficiency = (students, [minReviewersAmount]) => students.map(
  (student) => ({
    ...student,
    _notEnoughReviewers: student._volunteer ? false : (
      (student.reviewers || [])
        .filter((r) => +r.id !== +student.id)
        .length < minReviewersAmount
    ),
  }),
);

export const getStudentsWithHighlightedSelfReview = (students) => students.map(
  (student) => ({
    ...student,
    _examinees: (student._examinees || [])
      .map(
        (examine) => ({
          ...examine,
          _selfReview: +student.id === +examine.id,
        }),
      ),
  }),
);

export const getStudentsWithFilledExaminees = (students) => {
  const examinees = students.reduce(
    (acc, student) => ({
      ...acc,
      [student.id]: students.filter(
        (examine) => (examine.reviewers || [])
          .filter(({ id }) => +id === +student.id)
          .length,
      ).map((examine) => ({ id: examine.id, level: examine.level })),
    }),
    {},
  );

  return students.map(
    (student) => ({
      ...student,
      _examinees: examinees[student.id] || [],
    }),
  );
};

export const getCalculatedStudentsReviews = (weeks) => weeks
  .sort((w1, w2) => (w1.date || "0").localeCompare(w2.date))
  .reduce((acc, { participants }, i) => {
    const prevWeekParticipants = acc[i - 1] || {};
    return [
      ...acc,
      {
        ...prevWeekParticipants,
        ...participants.reduce(
          (thisWeekParticipants, participant) => ({
            ...thisWeekParticipants,
            [participant.id]: (participant.reviewers || []).reduce(
              (reviewers, { id }) => ({
                ...reviewers,
                [id]: ((prevWeekParticipants[participant.id] || {})[id] || 0) + 1,
              }),
              prevWeekParticipants[participant.id] || {},
            ),
          }), {}
        ),
      },
    ];
  }, [])
  .sort((w1, w2) => (w2.date || "0").localeCompare(w1.date));

/**
 *
 *
 * @param selectedReviewers {1:[6]}
 * @param week {participants: [{id: 1, level: 3, ...]}]}
 *
 * @returns {*} [{id: 1, level: 3, reviewers: [{id: 6, level: 3}]}]
 */
export const restoreParticipants = (selectedReviewers, week = { participants: [] }) => {
  const students = arrayToObject(week.participants, "id");
  return {
    ...week,
    participants: Object.keys(selectedReviewers).map(
      (studentId) => ({
        id: studentId,
        level: (students[studentId] || {}).level,
        preferredLevel: (students[studentId] || {}).preferredLevel,
        _volunteer: (students[studentId] || {})._volunteer,
        reviewers: selectedReviewers[studentId].map(
          (id) => ({
            id,
            level: (students[id] || {}).level,
          }),
        ),
      }),
    ),
  };
};


export const formatSnapshot = (participants, selectedReviewers, date?) => {
  const students = arrayToObject(participants, "id");
  return {
    date,
    volunteers: Object.keys(selectedReviewers)
      .filter((id) => students[id]._volunteer)
      .map((id) => ({
        id,
        level: students[id].level,
        preferredLevel: students[id].preferredLevel || null,
      })),

    students: Object.keys(selectedReviewers)
      .filter((id) => !students[id]._volunteer)
      .reduce((studs, studentId) => ({
        ...studs,
        [studentId]: {
          level: students[studentId].level,
          reviewers: students[studentId].reviewers.reduce(
            (reviewers, { level, id }) => ({
              ...reviewers,
              // because we fill user's reviewers with himself,
              // it's kind of filtering to leave only reviewers that are not a user
              [level]: [...(reviewers[level] || []), ...(+id === +studentId ? [] : [id])],
            }),
            {},
          ),
        },
      }),
      {}),
  };
};

// from [{students: { [id]: data }}] to [{students: [ {id, ...data } ]]
export const refactorSnapshot = (w) => ({
  ...w,
  volunteers: w.volunteers.map((v) => ({
    ...v,
    // this is an awful hardcode
    // TODO: think about it later
    // level: 17,
    _volunteer: true,
  })),
  students: objectToArray(w.students, "id")
    .map(
      (student) => ({
        ...student,
        // from {[level]: [id]} to [{id, level}]
        reviewers: reviewersObjectToArray(student.reviewers),
      }),
    ),
});
