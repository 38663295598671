import { combineReducers } from "redux";
import users from "./users";
import review from "./reviewPlanner";
import general from "./general";

export default combineReducers({
  users,
  review,
  general,
});
