import {toast} from "react-toastify";
import {
  PUSH_SELECTED_USER,
  SET_PARTICIPANTS_TABLE_DATA,
  DELETE_SELECTED_USER,
  DELETE_SELECTED_PARTICIPANT,
  PUSH_COLUMNS,
  CLEAR_PARTICIPANTS,
  CLEAR_COLUMNS,
  SELECT_COLUMN_TO_DISPLAY,
  DELETE_COLUMN_TO_DISPLAY,
  SET_COLUMNS_TO_DISPLAY,
  SET_ANOMALY_OF_REVIEWS,
  SET_REVIEW_PLAN,
  SET_GROUPS,
  SET_USER_FIRST_ATTENDING, SET_REVIEW_PLAN_WEEK,
} from "./actionTypes";

export const pushUser = (selectedUsers) => ({
  type: PUSH_SELECTED_USER,
  payload: {selectedUsers},
});

export const pushColumns = (columns) => ({
  type: PUSH_COLUMNS,
  payload: {columns},
});

export const selectColumnsToDisplay = (columnId, slug) => ({
  type: SELECT_COLUMN_TO_DISPLAY,
  payload: {columnId, slug},
});

export const setColumnsToDisplay = (columnsToDisplay, slug) => ({
  type: SET_COLUMNS_TO_DISPLAY,
  payload: {columnsToDisplay, slug},
});

export const deleteColumnsToDisplay = (id, slug) => ({
  type: DELETE_COLUMN_TO_DISPLAY,
  payload: {id, slug},
});

export const deleteSelectedUser = (email) => ({
  type: DELETE_SELECTED_USER,
  payload: {email},
});

export const deleteSelectedParticipant = (id) => ({
  type: DELETE_SELECTED_PARTICIPANT,
  payload: {id},
});

export const setTableData = (participants) => ({
  type: SET_PARTICIPANTS_TABLE_DATA,
  payload: {participants},
});

export const clearParticipants = () => ({
  type: CLEAR_PARTICIPANTS,
  payload: {},
});

export const clearColumns = () => ({
  type: CLEAR_COLUMNS,
  payload: {},
});

export const setMarkData = (data) => ({
  type: "SET_All_USERS_MARK_DATA",
  payload: data,
});

export const setAnomaly = (data) => ({
  type: SET_ANOMALY_OF_REVIEWS,
  payload: data,
});

export const setReviewPlan = (data) => ({
  type: SET_REVIEW_PLAN,
  payload: data,
});
export const setReviewPlanWeek = (data) => ({
  type: SET_REVIEW_PLAN_WEEK,
  payload: data,
});

export const changeFirstAttendingType = (id) => ({
  type: SET_USER_FIRST_ATTENDING,
  payload: id,
});

export const setGroupStartDates = (data) => ({
  type: SET_GROUPS,
  payload: data,
});


export const notifyWithErrors = (errorMessages) => errorMessages
  .forEach(
    (e) => toast.warn(e, {className: "has-background-danger"})
  );


export const notifyWithSuccess = (successMessages) =>
  () => successMessages.forEach(
    (e) => toast.warn(e, {className: "has-background-success"})
  );
