export const PUSH_SELECTED_USER = "PUSH_SELECTED_USER";
export const PUSH_COLUMNS = "PUSH_COLUMNS";
export const SELECT_COLUMN_TO_DISPLAY = "SELECT_COLUMN_TO_DISPLAY";
export const SET_COLUMNS_TO_DISPLAY = "SET_COLUMNS_TO_DISPLAY";
export const DELETE_COLUMN_TO_DISPLAY = "DELETE_COLUMN_TO_DISPLAY";
export const CLEAR_COLUMNS = "CLEAR_COLUMNS";
export const CLEAR_PARTICIPANTS = "CLEAR_PARTICIPANTS";
export const DELETE_SELECTED_USER = "DELETE_SELECTED_USER";
export const DELETE_SELECTED_PARTICIPANT = "DELETE_SELECTED_PARTICIPANT";
export const SET_PARTICIPANTS_TABLE_DATA = "SET_PARTICIPANTS_TABLE_DATA";
// export const SET_All_USERS_MARK_DATA = 'SET_All_USERS_MARK_DATA';
export const SET_ANOMALY_OF_REVIEWS = "SET_ANOMALY_OF_REVIEWS";
export const SET_REVIEW_PLAN = "SET_REVIEW_PLAN";
export const SET_REVIEW_PLAN_WEEK = "SET_REVIEW_PLAN_WEEK";
export const SET_GROUPS = "SET_GROUPS";
export const SET_USER_FIRST_ATTENDING = "SET_USER_FIRST_ATTENDING";
