import {Student, TableColumn} from "../../react-app-env";
import {logInAsUser} from "../../redux/inquiries";
import {NavLink} from "react-router-dom";
import React from "react";
import {copyToClipboard} from "../../utils";

export default [
  {
    Header: "№",
    id: '№',
    defaultVisibility: true,
    width: 30,
    Cell: ({viewIndex}) => viewIndex + 1,
    fixed: 'left'
  },
  {
    Header: "Img",
    id: 'avatar',
    defaultVisibility: true,
    width: 30,
    Cell: ({ original }: { original: Student }) => (<img src={original.avatar} className="avatar"/>),
    fixed: 'left'
  },
  {
    Header: "Фамилия, Имя",
    defaultVisibility: true,
    width: 190,
    accessor: "lastName",
    id:  "lastName",
    fixed: 'left',
    Cell: ({original}: { original: Student }) => (
      <p>
        <a
          href={`${process.env.REACT_APP_PROFILES_URL}/u/${original.nickname}`}
          target="_blank"
          rel="noopener noreferrer"
          className="is-link">
        {original.lastName}
        &nbsp;
        {original.firstName}
        </a>
        &nbsp;
        <button
          type="button"
          className="button is-small"
          onClick={() => logInAsUser(original)}
        >
              <span className="icon is-small">
              <img src="/img/sign-in.svg" alt="sign in"/>
              </span>
        </button>
      </p>
    ),
  },
  {
    Header: "Досье",
    defaultVisibility: true,
    accessor: "id",
    id:  "id",
    width: 60,
    Cell: ({original: {id}}: { original: Student }) =>
      <a href={process.env.REACT_APP_DOSSIER_URL + '/' + id}  rel="noopener noreferrer" target="_blank">досье</a>,
  },
  {
    Header: "Age",
    defaultVisibility: true,
    accessor: "birthday",
    id:  "birthday",
    width: 40,
    Cell: ({original: {birthday}}: { original: Student }) =>
      <span>{~~((Date.now() - (+new Date(birthday))) / 1000 / 60 / 60 / 24 / 365)}</span>,
    className: "has-text-right",
  },
  {
    Header: "Email",
    defaultVisibility: true,
    accessor: "email",
    id:  "email",
    width: 200
  },
  {
    Header: "Город",
    defaultVisibility: true,
    accessor: "city",
    id:  "city",
    width: 200
  },
  {
    Header: "Телефон",
    defaultVisibility: true,
    accessor: "phone",
    id:  "phone",
    Cell: ({original}: { original: Student }) => <kbd><a href={`tel:${original.phone}`}>{original.phone}</a></kbd>,
  },
  {
    width: 180,
    Header: "Discord",
    defaultVisibility: true,
    accessor: "discord",
    id:  "discord",
    Cell: ({original}: { original: Student }) => original.discord ? (<button onClick={() => copyToClipboard(original.discord)} className="discord-link"><code>{original.discord}</code></button>) : '',
  },
  {
    id: "logs",
    Header: "Лог",
    defaultVisibility: true,
    width: 40,
    accessor: "id",
    Cell: ({original}) => <NavLink to={`/logs?id=${original.id}`}>Лог</NavLink>,
  }
] as TableColumn[]
