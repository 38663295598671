import { SET_REVIEW_PLAN, SET_REVIEW_PLAN_WEEK } from "../actionTypes";
import {
  arrayToObject,
  mergeObjectFields,
} from "../../utils";
import {
  getCalculatedStudentsReviews,
  refactorSnapshot,
} from "../../utils/reviewPlanner";

const initialState = {
  snapshots: [
    {
      users: {},
      date: null
    }
  ],
  reviewsAmount: [],
  reviewersAmount: {
    max: 0,
    min: 0,
  },
};

export default function (state = initialState, action) {
  let users;
  let reviewsAmount;
  switch (action.type) {
    case SET_REVIEW_PLAN:
      users = arrayToObject(action.payload.users, "id");
      const { reviewersAmount } = action.payload;
      const snapshots = action.payload.snapshots
        // from [{students: { [id]: data }}] to [{students: [ {id, ...data } ]]
        .map(refactorSnapshot)
        .map((week) => mergeObjectFields(week, "participants", "students", "volunteers"));
      snapshots[0].users = users;
      const today = new Date().toLocaleDateString('uk').split('.').reverse().join('-')
      reviewsAmount = getCalculatedStudentsReviews(snapshots.filter( s => s.date !== today));
      return {
        ...state,
        users,
        reviewersAmount,
        snapshots: snapshots.sort((w1, w2) => (w2.date || "0").localeCompare(w1.date)),
        reviewsAmount,
      };
    case SET_REVIEW_PLAN_WEEK:
      users = arrayToObject(action.payload.users, "id");
      // from [{students: { [id]: data }}] to [{students: [ {id, ...data } ]]
      const snapshot = mergeObjectFields(refactorSnapshot(action.payload.snapshot),"participants", "students", "volunteers")
      snapshot.users = users;
      reviewsAmount = getCalculatedStudentsReviews(state.snapshots);
      return {
        ...state,
        users,
        snapshots: [
          ...state.snapshots.filter(w => w.date !== snapshot.date),
          snapshot
        ].sort((w1, w2) => (w2.date || "0").localeCompare(w1.date)),
        reviewsAmount,
      };
    default:
      return state;
  }
}
