import CURRENT_STUDENTS_COLUMNS from "./current";
import GRADUATED_STUDENTS_COLUMNS from "./graduated";
import VOLUNTEERS_COLUMNS from "./volunteers";
import POTENTIAL_STUDENTS_COLUMNS from "./potential";

export default [
  {
    title: "Потенциальные ученики",
    path: "students/potential",
    slug: "potential",
    columns: POTENTIAL_STUDENTS_COLUMNS,
  },
  {
    title: "Текущие ученики",
    path: "students/current",
    slug: "current",
    columns: CURRENT_STUDENTS_COLUMNS,
  },
  {
    title: "Волонтеры",
    path: "students/volunteers",
    slug: "volunteers",
    columns: VOLUNTEERS_COLUMNS,
  },
  {
    title: "Выпускники",
    path: "students/graduates",
    slug: "graduates",
    columns: GRADUATED_STUDENTS_COLUMNS,
  }
];
