import React from "react";
import { Table, Heading, Form, Pagination } from "react-bulma-components";
import { getUserLogs } from "../redux/inquiries";
import {RouteComponentProps} from "react-router-dom";
import {Student} from "../react-app-env";


// progress | homework | link | mark | review | feedback | notification | data | warnings

const filters = {
  "all": "Всё",
  "progress": "Прогресс",
  "homework": "Домашки",
  "link": "Открытия ссылок",
  "mark": "Оценки",
  "review": "Ревью",
  "feedback": "Отзывы",
  "notification": "Уведомления",
  "data": "Данные",
  "warnings": "Предупреждения",
  "markForCode": "Оценки за код",
  "givenMarkForReview": "Выставленные невидимые оценки",
  "receivedMarkForReview": "Полученные невидимые оценки",
  "visibleWarnings": "Видимые предупреждения",
  "hiddenWarnings": "Невидимые предупреждения",

};

interface Log {
  level: number,
  event: string,
  message: string,
  owner: string,
  category: string,
  createdAt: number
}

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {}

type LogsProps = RouteComponentProps<PathParamsType> & {}

type LogsState = {
  logs: Log[],
  user: Student,
  totalAmount: number,
  unlistenHistory: () => void
}

export default class Logs extends React.Component<LogsProps, LogsState> {
  constructor(props) {
    super(props);
    this.state = {
      user: {} as Student,
      logs: [],
      totalAmount: 0,
      unlistenHistory: this.props.history.listen(async (location) => {
        if(location.pathname === "/logs") {
          window.scrollTo(0, 0);
          await this.getLogsOrRedirect()
        }
      })
    };


  }
  componentWillUnmount() {
    this.state.unlistenHistory()
  }

  getQueryParams() {
    const query = new URLSearchParams(this.props.history.location.search);
    // @ts-ignore
    return [...query.keys()].reduce((acc, name) => ({
      ...acc,
      [name]: query.get(name)
    }), {});
  }

  async getLogsOrRedirect(override = {}) {
    const { id, pack, sort }: any = {...this.getQueryParams(), ...override};
    if(id && pack) {
      this.setState(await getUserLogs({ pack: pack - 1, userId: id, sort: sort === "all" ? null : sort}))
    } else {
      if (!id) {
        this.props.history.replace("/");
      } else {
        this.props.history.replace(`/logs?id=${id}&pack=${pack || 1}&sort=${sort || "all"}`);
      }
    }
  }

  updateFilter(e) {
    const { id, pack } = this.getQueryParams();
    this.props.history.replace(`/logs?id=${id}&pack=${pack || 1}&sort=${e.target.value}`);
  }
  updatePack(e) {
    const { id, sort } = this.getQueryParams();
    this.props.history.replace(`/logs?id=${id}&pack=${e}&sort=${sort}`);
  }
  async componentDidMount() {
    await this.getLogsOrRedirect()
  }

  render() {
    const { sort, id, pack } = this.getQueryParams();
    const linkOrText = (maybeLink) => (maybeLink.startsWith("http")
      ? <a target="_blank" rel="noopener noreferrer" href={maybeLink}>{maybeLink}</a>
      : maybeLink);

    return (
      <div>
        <Heading>История пользователя: {this.state.user.firstName} {this.state.user.lastName} (id: {id})</Heading>
        <Form.Field>
          <Form.Label>Оставить только</Form.Label>
          <Form.Control>
            <select
              onChange={this.updateFilter.bind(this)}
              value={sort}
            >
              {Object.keys(filters).map((key: string) => <option value={key} key={key}>{filters[key]}</option>)}
            </select>
          </Form.Control>
        </Form.Field>
        <Table>
          <thead>
            <tr>
              <th>Когда?</th>
              <th>Категория</th>
              <th>Level</th>
              <th>Кто?</th>
              <th>Что произошло?</th>
              <th>Связанные данные</th>
            </tr>
          </thead>
          <tbody>
            {this.state.logs.map((log, i) => (
              <tr key={i}>
                {/*<td>{60*pack+(i+1)}</td>*/}
                <td>{log.createdAt ? new Date(log.createdAt*1000).toLocaleString()
                  .replace(/\//g, "\u2011")
                  .replace(/,/g, "") : "неизвестно"}</td>
                <td>{filters[log.category]}</td>
                <td>{log.level}</td>
                <td>{log.owner}</td>
                <td>{log.event}</td>
                <td>{linkOrText(log.message || "")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          onChange={this.updatePack.bind(this)}
          current={+pack}
          next="Дальше"
          previous="Назад"
          total={Math.ceil(this.state.totalAmount / 60)}
          delta={5} />
      </div>
    );
  }
}
