import React from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bulma-components";
import {fetchAddUserToReview, fetchParticipants} from "../redux/inquiries";
import {RouteComponentProps, withRouter} from "react-router";

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {
  type: string
}

type AddUserToReviewModalProps = RouteComponentProps<PathParamsType> & {
  data: any,
  close: () => void,
  fetchParticipants: (slug: string) => Promise<void>
}

type AddUserToReviewModalState = {
  availability: string,
  formRef: React.RefObject<HTMLFormElement>
}

class AddUserToReviewModal extends React.Component<AddUserToReviewModalProps, AddUserToReviewModalState> {
  constructor(props) {
    super(props);
    this.state = {
      availability: "",
      formRef: React.createRef()
    };
  }

  async addUserToReview() {
    const formData = new FormData(this.state.formRef.current || undefined);
    formData.set('userId', this.props.data.id);
    await fetchAddUserToReview(formData);
    await this.props.fetchParticipants(this.props.match.params.type);
  };

  render() {
    return (
      <Modal.Card>
        <form onSubmit={(e) => {e.preventDefault()}} ref={this.state.formRef}>
          <Modal.Card.Head onClose={this.props.close} showClose>
            <Modal.Card.Title>
              Вы уверены что хотите добавить
              <b>{this.props.data.firstName} {this.props.data.lastName}</b> задним числом на ревью?
            </Modal.Card.Title>
          </Modal.Card.Head>
          <Modal.Card.Body>
            <div className="field">
              <label htmlFor="availability"><b>Доступность (необязательно)</b></label>
              <div className="control">
                <input
                  id="availability"
                  name="availability"
                  className="input is-black"
                  type="text"
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="homework"><b>Архив с домашкой (необязательно)</b></label>
              <div className="control">
                <input
                  id="homework"
                  name="homework"
                  className="input is-black"
                  type="file"
                />
              </div>
            </div>
          </Modal.Card.Body>
          <Modal.Card.Foot style={{ alignItems: "end", justifyContent: "end" }}>
            <Button onClick={() => this.props.close()}>
            Отмена
            </Button>
            <Button
              color="black"
              onClick={async () => {
                await this.addUserToReview();
                this.props.close();
              }}
            >
              Отправить
            </Button>
          </Modal.Card.Foot>
        </form>
      </Modal.Card>
    );
  }
}

const mapStateToProps = (state) => ({
  columns: state.users.columns,
  columnsToDisplay: state.users.columnsToDisplay,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchParticipants
  })(AddUserToReviewModal)
);
