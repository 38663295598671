import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import "bulma-extensions/dist/js/bulma-extensions";
import "bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip.min.css";
import {Modal} from "react-bulma-components";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import DropUserModal from "./ModalDropUser";
import AddUserToReviewModal from "./ModalAddUserToReview";
import {ActiveStudent, ColumnsToDisplay, PotentialStudent, Student, TableColumn} from "../react-app-env";
import store from "../redux/store";
import {pushUser, clearColumns} from "../redux/actions";
import {
  fetchAttendFirstMeeting,
  fetchParticipants,
  acceptUserToStudying,
  setUserWarningsLimit,
  setUserActuality
} from "../redux/inquiries";
import defaultColumns from "../config/tableColumns/default";
import {inRange} from "../utils"; // important: this line must be placed after react-table css import

const ReactTableFixedColumns = withFixedColumns(ReactTable);

type PathParamsType = {
  type: string
}

type DataTableProps = RouteComponentProps<PathParamsType> & {
  columns,
  selectedUsers: string[],
  startDates: string[],
  participants: Array<{ email: string }>
  columnsToDisplay: ColumnsToDisplay,
  fetchParticipants: (arg0: string) => void,
  fetchAttendFirstMeeting: (arg0: number) => void,
  acceptUserToStudying: (id: number) => void,
  setUserWarningsLimit: (params: any) => void,
  setUserActuality: (params: any) => void,
}

type DataTableState = {
  selectAll: number,
  modal: {
    dropUser: {
      visible: boolean,
      data: any,
    },
    addUserToReview: {
      visible: boolean,
      data: any
    }
  }
}

class DataTable extends React.Component<DataTableProps, DataTableState> {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: 0,
      modal: {
        dropUser: {
          visible: false,
          data: {},
        },
        addUserToReview: {
          visible: false,
          data: {}
        }
      }
    };
  }

  componentWillUnmount() {
    store.dispatch(clearColumns());
  }

  async setWarningsLimit(origin) {
    // @ts-ignore
    let newLimit: string | null | number = prompt(`Сколько ворнингов выставить для ${origin.firstName} ${origin.lastName}?`, origin.additional.warnings.limit);
    if (newLimit === null) {
      return;
    }
    newLimit = Number(newLimit);
    if (newLimit < 0) {
      alert('Нельзя выставить негативное число ворнингов.');
      return;
    }
    if (`${newLimit}` === 'NaN') {
      alert('Введенное количество - это не число.');
      return;
    }
    await this.props.setUserWarningsLimit({userId: origin.id, warningsLimit: newLimit});
    await this.props.fetchParticipants(this.props.match.params.type);
  }

  getTrProps(state, rowInfo) {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.original.additional.cheater ? "rgba(255,0,0,0.2)" : "white",
        },
      };
    }
    return {};
  };

  open(modalType: string, data) {
    this.setState({
      modal: {
        ...this.state.modal,
        [modalType]: {
          data,
          visible: true,
        }
      }
    });
  };

  close(modalType: string) {
    this.setState({
      modal: {
        ...this.state.modal,
        [modalType]: {
          visible: false,
          data: {}
        }
      }
    });
  }

  getVisibleColumns(): TableColumn[] {
    return [...defaultColumns, ...this.props.columns]
      .filter((col) => this.props.columnsToDisplay[this.props.match.params.type].includes(col.id));
  }

  async attendFirstMeetingForUser(id) {
    await this.props.fetchAttendFirstMeeting(id);
  }

  toggleRow(email) {
    let newSelected = [] as string[];
    const {selectedUsers} = this.props;
    if (selectedUsers.some((selectedUser) => selectedUser === email)) {
      newSelected = selectedUsers.filter((selectedUser) => selectedUser !== email);
    } else {
      newSelected = [...selectedUsers, email];
    }
    this.setState({
      selectAll: 2,
    });
    store.dispatch(pushUser(newSelected));
  }

  toggleSelectAll() {
    const newSelected = [] as string[];
    if (this.state.selectAll === 2 || this.state.selectAll === 0) {
      this.props.participants.forEach((user) => {
        newSelected.push(user.email);
      });
      this.setState({selectAll: 1});
    } else {
      this.setState({selectAll: 0});
      store.dispatch(pushUser(newSelected));
    }
    store.dispatch(pushUser(newSelected));
  }

  async pushPotentialStudentToStudying(user: PotentialStudent) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Вы точно хотите задним числом закинуть ${user.firstName} ${user.lastName} в последнюю стартовавшую группу?`)) {
      await this.props.acceptUserToStudying(user.id);
      await this.props.fetchParticipants(this.props.match.params.type);
    }
  }

  async setUserActuality(user: PotentialStudent) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Правильно понимаю, что ${user.firstName} ${user.lastName} больше не хочет учиться в Ш++?`)) {
      await this.props.setUserActuality({
        email: user.email,
        actual: false
      });
      await this.props.fetchParticipants(this.props.match.params.type);
    }
  }

  render() {
    const {participants} = this.props;
    const {type} = this.props.match.params;
    if (!participants || !participants.length) {
      return (<div>Нет данных.</div>);
    }
    let tableColumns: TableColumn[] = [
      {
        accessor: "",
        fixed: 'left',
        Cell: ({original}: { original: Student }) => (
          <input
            type="checkbox"
            className="checkbox"
            checked={this.props.selectedUsers.some((selectedUser) => selectedUser === original.email)}
            onChange={() => this.toggleRow(original.email)}
          />
        ),
        Header: () => (
          <input
            type="checkbox"
            className="checkbox"
            checked={this.state.selectAll === 1}
            onChange={() => this.toggleSelectAll()}
          />
        ),
        sortable: false,
        width: 35,
      },
      ...this.getVisibleColumns()
    ];


    if (type === "potential") {
      const lastStartDate = this.props.startDates.find((lastStartDate) => inRange(new Date().getTime() - new Date(lastStartDate).getTime(), 0, 1000 * 60 * 60 * 24 * 6))

      tableColumns.push({
        Header: "Действия",
        accessor: '',
        width: 400,
        Cell: ({original}: { original: PotentialStudent }) => (
          <div className="buttons has-addons">
            {lastStartDate &&
            <button
              type="button"
              onClick={() => this.pushPotentialStudentToStudying(original)}
              className="button is-small"
            >
              Закинуть в старт {lastStartDate}
            </button>
            }
            {original.additional.typeOfStudent !== 'не будет учиться' &&
            <button
              type="button"
              onClick={() => this.setUserActuality(original)}
              className="button is-small"
            >
              Не будет учиться
            </button>}
          </div>
        ),
      })
    }

    if (type === "current") {
      tableColumns = [
        ...tableColumns,
        {
          Header: "1я ок",
          className: "centered",
          accessor: 'original.additional.attendFirstMeeting',
          width: 70,
          Cell: ({original}: { original: ActiveStudent }) => (!original.additional.attendFirstMeeting
            ? (
              <button
                type="button"
                className="button is-success is-small"
                onClick={() => this.attendFirstMeetingForUser(original.id)}
              >
                1Я ОК
              </button>
            ) : <span>ок</span>),
        },
        {
          Header: "Действия",
          accessor: '',
          width: 500,
          Cell: ({original}: { original: ActiveStudent }) => (
            <div className="buttons has-addonss">
              <button
                type="button"
                onClick={() => this.setWarningsLimit(original)}
                className="button is-small"
              >
                Поднять лимит ворнингов
              </button>
              &nbsp;
              <button
                type="button"
                onClick={() => {
                  this.open("dropUser", {
                    ...original,
                    actionType: "dropFromStudy",
                  });
                }}
                className="button is-danger is-small"
              >
                Сбросить с обучения
              </button>
              &nbsp;
              {original.additional.currentReview
                ? (<button
                  type="button"
                  onClick={() => {
                    this.open("dropUser", {
                      ...original,
                      actionType: "dropFromReview"
                    });
                  }}
                  className="button is-warning is-small"
                >
                  Сбросить с ревью
                </button>)
                : new Date().getDay() === 3
                  ? (<button
                    type="button"
                    onClick={() => {
                      this.open("addUserToReview", original);
                    }}
                    className="button is-small"
                  >
                    Закинуть на ревью
                  </button>)
                  : null
              }
            </div>
          ),
        },
      ];
    }
    return (
      <div>
        <ReactTableFixedColumns
          data={participants}
          minRows={0}
          defaultPageSize={3000}
          columns={tableColumns}
          showPagination={true}
          loadingText="Загрузка..."
          noDataText="Записи не найдены"
          className="-striped -highlight"
          getTrProps={this.getTrProps}
        />
        <Modal
          closeOnBlur
          show={this.state.modal.dropUser.visible}
          onClose={() => this.close("dropUser")}
          showClose>
          <DropUserModal
            data={this.state.modal.dropUser.data}
            close={() => this.close("dropUser")}/>
        </Modal>
        <Modal
          closeOnBlur
          show={this.state.modal.addUserToReview.visible}
          onClose={() => this.close("addUserToReview")}
          showClose>
          <AddUserToReviewModal
            data={this.state.modal.addUserToReview.data}
            close={() => this.close("addUserToReview")}/>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedUsers: state.users.selectedUsers,
  columnsToDisplay: state.users.columnsToDisplay,
  columns: state.users.columns,
  participants: state.users.participants,
  startDates: state.general.startDates,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchParticipants,
    pushUser,
    fetchAttendFirstMeeting,
    acceptUserToStudying,
    setUserWarningsLimit,
    setUserActuality
  })(DataTable)
);
