import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { getGroupStartDates, saveGroupStartDates } from "../redux/inquiries";

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {}

type GroupStartDatesProps = RouteComponentProps<PathParamsType> & {
    startDates,
    getGroupStartDates,
    saveGroupStartDates
}

type GroupStartDatesState = {
    startDates: string
}

class GroupStartDates extends React.Component<GroupStartDatesProps, GroupStartDatesState> {
  constructor(props) {
    super(props);
    this.state = {
      startDates: "[]",
    };
  }

  async componentDidMount() {
    await this.props.getGroupStartDates();
    this.setState({ startDates: JSON.stringify(this.props.startDates, null, 2) });
  }

  async saveStartDates() {
    await this.props.saveGroupStartDates({
      startDates: JSON.parse(this.state.startDates),
    });
    alert("сохранено!");
  }

  render() {
    return (
      <div>
        <p><b>Правила использования этой страницы:</b></p>
        <p>Нельзя добавлять дату раньше, чем ближайшая указанная</p>
        <p>Пожалуйста, не оставляйте лишние запятые, кавычки или скобки</p>
        <p>Формат даты: YYYY-mm-dd (напр. 2018-01-01)</p>
        <p>
Расписание из двух, например, дат выглядит примерно так:
          <code>["2018-03-18","2018-04-15"]</code>
        </p>
        <div className="field has-addons">
          <div className="control is-expanded">
            <textarea
                className="textarea"
                rows={7}
                value={this.state.startDates}
                onChange={(e) => this.setState({ startDates: e.target.value })} />
          </div>
          <div className="control">
            <button
                type="button"
                className="button is-primary"
                onClick={() => this.saveStartDates()}
            >Отправить</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.general,
});


export default withRouter(
  connect(mapStateToProps, {
    getGroupStartDates,
    saveGroupStartDates,
  })(GroupStartDates),
);
