export function arrayToObject(array = [], keyName) {
  return array.reduce((acc, u) => ({
    ...acc,
    [u[keyName]]: u,
  }), {});
}

export function objectToArray(object = {}, keyName) {
  return Object.keys(object).map((key) => ({
    [keyName]: key,
    ...object[key],
  }));
}

export const mergeObjectFields = (object, resultFieldName, ...fieldsToMerge) => ({
  ...object,
  [resultFieldName]: fieldsToMerge.map((key) => object[key]).flat(1),
});
/**
 *
 * @param o - object to be changed
 * @param func - function to be applied to each object value
 * @returns {*}, object that in fact is the original one, but each value of it is mapped with the func
 */
export const mapObject = (o = {}, func) => Object.keys(o).reduce((acc, key) => ({
  ...acc,
  [key]: func(o[key], key),
}), {});

interface UserReviewersBack {
  [level: number]: number[]
}

interface UserReviewersFront {
  level: number,
  id: number
}
/**
 * converts { [level]: [id, id] }
 * to [ { level: 4, id: 4 }, { level: 4, id: 6 } ]
 * @param object
 * @returns array
 */
export const reviewersObjectToArray = (object: UserReviewersBack): UserReviewersFront[] =>
    Object.keys(object).reduce((acc: UserReviewersFront[], level: string): UserReviewersFront[] => ([
      ...acc,
      ...object[level].map((id) => ({ id, level })),
    ]), []);

export const augmentAndFillArray = (array: any[], minLength: number, maxLength: number, defaultFillValue: any) => [
  ...Array.from(new Uint8Array(minLength)).fill(defaultFillValue),
  ...array.slice(minLength, maxLength),
].map((_, i) => +(array[i] || _));

export const decodeHTMLText = (str) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
};

export const formatPhone = (phone) => {
  let p = (phone || "").replace(/[+()\- ]+/g, "");

  if (p.startsWith("38")) {
    p = p.slice(3);
  }
  if (p[0] !== "0" && p.length > 0) {
    p = `0${p}`;
  }
  return p;
};

export const last = (arr: any[], offset: number = 0): any => arr.length > 0 - offset ? arr[arr.length - 1 + offset] : arr[0]

function fallbackCopyToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');

  document.body.removeChild(textArea);
}

export function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
}

export const inRange = (num, from, to) => num >= from && num <= to;
