import React from "react";
import "bulma-extensions/bulma-checkradio/dist/css/bulma-checkradio.min.css";
import "bulma-extensions/bulma-accordion/dist/css/bulma-accordion.min.css";
import bulmaAccordion from "bulma-extensions/bulma-accordion/dist/js/bulma-accordion.min";
import { connect } from "react-redux";
import { deleteColumnsToDisplay, selectColumnsToDisplay, } from "../redux/actions";
import {ColumnsToDisplay, TableColumn} from "../react-app-env";
import store from "../redux/store";
import {RouteComponentProps, withRouter} from "react-router";
import defaultColumns from "../config/tableColumns/default";

type PathParamsType = {
  type: string
}

type TableColumnsDisplayProps = RouteComponentProps<PathParamsType> & {
  columnsToDisplay: ColumnsToDisplay,
  columns: TableColumn[],
}

class TableColumnsDisplay extends React.Component<TableColumnsDisplayProps> {
  componentDidMount() {
    bulmaAccordion.attach();
  }

  handleColumnCheck(column) {
    const { columnsToDisplay } = this.props;
    const { type } = this.props.match.params;
    if (columnsToDisplay[type].some((colId) => colId === column.id)) {
      store.dispatch(deleteColumnsToDisplay(column.id, type));
    } else {
      store.dispatch(selectColumnsToDisplay(column.id, type));
    }
  }

  render() {
    let optionalColumns = [] as JSX.Element[];
    const { columns, columnsToDisplay } = this.props;
    const { type } = this.props.match.params;
    if (columns) {
      optionalColumns = [ ...defaultColumns, ...columns ].map((column: TableColumn): JSX.Element => (
        <div className="field column is-narrow" key={column.Header as string}>
          <label htmlFor={column.id}>
            <input
              className="is-checkbox"
              id={column.id}
              type="checkbox"
              checked={columnsToDisplay[type].includes(column.id || "")}
              onChange={() => this.handleColumnCheck(column)}
            />&nbsp;{column.Header}
          </label>
        </div>
      ));
    }
    return (
      <div className="accordions">
        <div className="accordion">
          <div className="accordion-header has-background-grey toggle">Выберите колонки для отображения в таблице</div>
          <div className="accordion-body">
            <div className="accordion-content columns is-multiline">
              {optionalColumns.length ? optionalColumns : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  columns: state.users.columns,
  columnsToDisplay: state.users.columnsToDisplay,
});

export default withRouter(connect(mapStateToProps)(TableColumnsDisplay));
